/* My Styles */
html,
body {
  box-sizing: border-box;
  width: 100%;
  margin: 0 auto;
  font-size: 62.5%;
  line-height: 1.5;
}

.App {
  text-align: center;
  color: #666666;
  font-size: 1.6rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  text-transform: uppercase;
  color: #333333;
}

h1 {
  font-size: 3rem;
}

h2 {
  font-size: 2.4rem;
}

h3 {
  font-size: 2rem;
}

h4 {
  font-size: 2.2rem;
}

h5 {
  font-size: 1.8rem;
}

h6 {
  font-size: 1.6rem;
}

button {
  width: 75px;
  height: 40px;
  margin: 5px 10px;
}

li {
  line-height: 2;
}

.btn.btn-secondary {
  background-color: #c61c1c;
  border: none;
  font-size: 1.6rem;
  cursor: pointer;
}

.btn.btn-secondary:hover {
  background-color: #313131;
}

/* navbar styles */
.navBar {
  display: flex;
  background-color: #f8f9fa;
  justify-content: space-evenly;
  align-items: center;
}

.riseNavLogo_a,
.hamburgerMenu {
  display: none;
}

.desktopNav {
  align-items: center;
}

a.nav-link {
  color: #666666;
  text-transform: uppercase;
  padding-bottom: 0;
}

a.nav-link:hover {
  color: #c61c1c;
}

a.dropdownMenu.btn.btn-secondary {
  background-color: #f8f9fa;
  color: #666666;
  text-transform: uppercase;
  padding-bottom: 0;
}

a.dropdownMenu.btn.btn-secondary:hover {
  color: #c61c1c;
}

a.dropdown-item {
  font-size: 1.6rem;
  text-transform: uppercase;
}

a.dropdown-item:hover {
  background-color: #d3d3d3;
  color: #c61c1c;
  /* font-weight: 500; */
}

.fa-phone-alt {
  margin-left: 15px;
}

.phxLogo {
  width: 75px;
}

/* Nav Hamburger Menu */
button.hamburgerNav.btn.btn-secondary {
  background-color: #222222;
  width: 60px;
}

/* footer styles */
footer {
  background-color: #222222;
  color: white;
}

footer h3 {
  color: #c61c1c;
}

.copyright {
  background-color: #313131;
  margin-bottom: 0;
  padding: 1%;
}

.footerTop {
  display: flex;
  justify-content: space-around;
  margin-bottom: 1.5%;
}

.footerTop div {
  display: flex;
  flex-direction: column;
  width: 33%;
  margin-top: 2%;
}

div.footerLogo {
  margin-top: 1%;
}

.footerContact {
  align-items: flex-start;
}

.footerContact div {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
}

.footerContact div,
.footerContact div p {
  margin: 0;
}

.markEmail,
.footerContact div,
.addressLink {
  margin-left: -10px;
}

.markEmail {
  margin-bottom: 0;
}

.footerContact div p.faxNumber {
  margin-left: -1%;
}

.footerSocial {
  align-items: flex-start;
}

.footerTop a {
  color: white;
  margin: 2%;
}

.footerTop i {
  font-size: 3rem;
  margin-right: 10px;
}

.footerNav {
  background-color: #313131;
  padding: 1% 3%;
}

.footerNav a {
  color: white;
}

.addressLink {
  text-align: left;
}

.footerTop a:hover {
  text-decoration: none;
  color: #c61c1c;
}

/* home page */
.homeHero {
  display: flex;
  background-color: #313131;
}

.homeHero div {
  text-align: left;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2% 0;
}

.homeHero div img {
  margin-left: -2%;
}

.homeHero p {
  padding: 4% 7% 2%;
}

.requestApptBtn.scheduleBtnHome {
  background-color: #c61c1c;
  margin-top: 5%;
}

.requestApptBtn.scheduleBtnHome:hover {
  background-color: #f8f9fa;
  color: #333333;
  font-weight: 500;
}

.riseDoorImg {
  width: 50%;
}

.introDiv {
  background-color: #f8f9fa;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 2%;
}

.introDiv p {
  padding: 1% 20%;
}

.introDiv h2,
.servicesList h2 {
  font-size: 2.8rem;
}

.videoDiv {
  width: 40%;
  padding: 1% 0 3%;
}

.servicesDiv {
  height: 70vh;
  color: white;
  display: flex;
}

.patientImg {
  width: 50%;
}

.servicesList {
  background-image: url("./images/riseDecal_vanilla2.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  text-align: left;
  width: 50%;
  padding: 2% 4%;
}

.servicesList h2 {
  color: white;
}

.servicesList p,
.servicesList ul {
  margin-top: 2%;
}

.servicesList li {
  list-style: disc;
  list-style-position: inside;
}

.learnBtn {
  width: 150px;
  margin-top: 5%;
}

/* Contact Page */
.contactPage {
  background-image: url("./images/tables_bw.jpg");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: 50%;
  display: flex;
  justify-content: space-evenly;
  color: white;
  padding: 2% 0;
}

.contactPage h2 {
  color: white;
  font-size: 3rem;
}

.businessInfo {
  text-align: left;
}

.businessInfo h3 {
  color: #c61c1c;
  font-size: 1.8rem;
  font-weight: bold;
}

.phoneFax {
  display: flex;
}

.phone a {
  color: white;
}

.phone a:hover {
  color: #c61c1c;
  text-decoration: none;
}

.fax {
  margin-left: 15%;
}

.busHours {
  margin-top: 5%;
}

.mapImg {
  margin-top: 5%;
}

/* Contact Form */
.contactForm {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 5%;
}

.contactFormDiv h2 {
  color: #c61c1c;
}

.nameInfo,
.contactInfo {
  display: flex;
}

#firstName,
#lastName,
#email,
#phone {
  width: 200px;
  height: 40px;
  margin: 5px 10px;
  font-size: 1.4rem;
}

#description {
  width: 420px;
  height: 200px;
  margin: 5px 10px;
  font-size: 1.4rem;
}

.submitBtn.btn.btn-secondary:hover {
  background-color: #666666;
}

/* Services Page */
.servicesHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.servicesImgDiv {
  background-image: url("./images/riseDecal_mercury.jpg");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: left;
  background-size: cover;
  padding-bottom: 2%;
}

.servicesImgDiv h1 {
  font-size: 3.6rem;
  color: white;
  margin-top: 2%;
}

.serviceHeaderImg {
  width: 20%;
  margin: 2% 1.5% 0;
  border-radius: 3px;
}

.equipImg {
  width: 25%;
}

.servicesHeaderText {
  text-align: left;
  width: 70%;
  line-height: 2;
}

.servicesHeaderText ul {
  margin-bottom: 3%;
}

.servicesHeaderText li {
  list-style: disc;
  list-style-position: inside;
}

.servicesHeaderText a {
  display: flex;
  align-items: center;
}

.servicesHeaderText i {
  color: #666666;
  margin-left: 1%;
}

.servicesHeaderText a li {
  color: #666666;
}

.servicesHeaderText a:hover {
  text-decoration: none;
  font-weight: 500;
}

.servicesHeaderText a li:hover,
.servicesHeaderText i:hover {
  color: #c61c1c;
}

.subheading {
  font-size: 1.8rem;
  font-weight: 600;
  margin-top: 2%;
}

.listHeading {
  font-weight: 500;
  margin-top: 2%;
}

#cashRates {
  background-color: #222222;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#cashRates img {
  margin: 1% 0;
}

.ratesCard {
  background-color: #f8f9fa;
  width: 50%;
  text-align: left;
  border-radius: 3px;
  padding: 2%;
  margin-bottom: 3%;
}

.followUp,
.manualPT {
  margin-top: 5%;
}

.PTEval,
.followUp,
.manualPT {
  display: flex;
}

.PTEval div,
.followUp div,
.manualPT div {
  width: 75%;
}

.PTEval h1,
.followUp h1,
.manualPT h1 {
  padding: 4% 5% 0 12%;
  font-size: 3.2rem;
}

.getStarted {
  background-color: #c61c1c;
}

.getStarted h1 {
  color: white;
  font-size: 3.2rem;
  padding: 2% 0 1%;
}

.planDiv {
  display: flex;
  justify-content: center;
}

.planDiv div {
  color: white;
  padding: 2%;
  width: 30%;
  height: 200px;
}

.planDiv div h3 {
  color: white;
  margin-top: 7%;
}

.planDiv div i {
  font-size: 3rem;
}

.requestApptBtn {
  background-color: #222222;
  color: white;
  border: none;
  border-radius: 0.25rem;
  width: 225px;
  height: 45px;
  margin-bottom: 2%;
}

.requestApptBtn:hover {
  background-color: #333333;
}

/* Sports Medicine */
.sportsMedHeader {
  background-image: url("./images/step_boxes.jpg");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: left;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3% 0;
}

.sportsMedHeader h1 {
  color: white;
}

.sportsMedHeader p {
  color: white;
  width: 40%;
  margin-top: 2%;
}

.headerImgDiv {
  display: flex;
  justify-content: center;
  margin-top: 3%;
}

.headerImgDiv img {
  width: 18%;
  margin: 2%;
  border-radius: 0.25rem;
}

.whyVisit {
  text-align: left;
  padding: 2% 6%;
  background-color: #f8f9fa;
}

.whyVisit div {
  display: flex;
  align-items: center;
  margin-top: 2%;
}

.whyVisit p {
  width: 60%;
}

.sportsMedBtn {
  background-color: #c61c1c;
  margin-left: 15%;
}

/* Shoulders Page */
.shouldersHeader {
  background-image: url("./images/shoulders.jpg");
  background-repeat: no-repeat;
  background-position-y: 20%;
  background-size: cover;
  height: 70vh;
  text-align: left;
  padding: 3% 4%;
}

.shouldersHeader h1 {
  color: #c61c1c;
  margin-bottom: 2%;
}

.shouldersHeader h2 {
  color: white;
}

.shouldersHeader p {
  width: 40%;
  color: white;
  margin-top: 2%;
}

.approachText {
  text-align: left;
  display: flex;
}

.approachText img {
  width: 50%;
}

.approachText div {
  padding: 2% 6% 0 4%;
}

.approachText h1 {
  margin-bottom: 3%;
}

.scheduleBtn {
  background-color: #c61c1c;
  margin: 7% 0;
}

/* Dry Needling Page */
.dryNeedlingHeader {
  background-image: url("./images/dryNeedling.jpg");
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
  height: 60vh;
  text-align: left;
  padding: 3% 4%;
  color: white;
}

.dryNeedlingHeader h1 {
  color: white;
  margin-top: 2%;
}

.dryNeedlingHeader p {
  width: 55%;
  margin-top: 2%;
}

.howItHelps {
  background-color: #f8f9fa;
  text-align: left;
  padding: 2% 4%;
}

.howItHelps h2 {
  margin-left: 3%;
}

.howItHelpsText {
  display: flex;
  align-items: center;
  margin: 2% 0 0 3%;
}

.howItHelps p {
  width: 50%;
}

.dryNeedleBtn {
  background-color: #c61c1c;
  margin-left: 15%;
}

.howItHelpsImgs {
  display: flex;
  justify-content: space-evenly;
  margin: 4% 0 2%;
}

.howItHelpsImgs img {
  width: 25%;
  border-radius: 3px;
}

.dryNeedlingIntake {
  background-color: #c61c1c;
  color: white;
  display: flex;
  text-align: left;
  align-items: center;
}

.dryNeedlingIntake div {
  width: 50%;
  margin: 2% 8%;
}

.dryNeedlingIntake h2 {
  color: white;
  margin-bottom: 4%;
}

.dryNeedlingIntake img {
  width: 30%;
  margin-right: 10%;
}

/* Patients Page */
.whatToExpect {
  background-color: #222222;
  display: flex;
  padding: 4% 2%;
}

.whatToExpectText {
  color: white;
  text-align: left;
  width: 50%;
  padding: 0 6% 0 5%;
}

.whatToExpectText h2 {
  color: white;
  margin-top: 8%;
}

.whatToExpectText p {
  margin-top: 5%;
}

.riseLobbyImg {
  width: 40%;
  padding: 0 4%;
  margin-left: 5%;
}

.uponArrival {
  background-color: #f8f9fa;
}

.uponArrival h1 {
  padding: 3% 0;
}

.whatYouNeed {
  display: flex;
  justify-content: space-evenly;
  margin: 0 5%;
}

.whatYouNeed div {
  width: 30%;
  padding: 1% 2% 3%;
}

.formsPageBtn {
  background-color: #c61c1c;
  margin-bottom: 3%;
}

/* Forms Page */
.formsPage {
  background-color: #f8f9fa;
}

.formsPage p {
  padding-top: 2%;
  margin-bottom: 0;
  color: white;
  font-weight: 600;
}

.formHeader {
  background-image: url("./images/forms.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 30vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.formHeader h1 {
  color: white;
  font-size: 3.6rem;
  margin-bottom: 1%;
}

.formHeader p {
  padding-top: 0;
  font-size: 1.8rem;
}

.patientForms {
  background-color: #c61c1c;
  padding: 3% 0 1%;
}

.patientForms h2 {
  color: white;
  font-size: 3rem;
}

.formsDiv {
  display: flex;
  justify-content: space-between;
  margin: 4% 6%;
}

.formsDiv a {
  width: 25%;
}

.formsDiv img {
  width: 70%;
}

/* Insurance Header */
.insuranceHeader {
  background-image: url("./images/stethoscope.jpg");
  background-repeat: no-repeat;
  background-position-y: 87%;
  background-size: cover;
  height: 40vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.insuranceHeader h1 {
  color: white;
  font-size: 3.6rem;
}

.insurancePlans {
  display: flex;
  flex-direction: column;
  text-align: left;
  background-color: #f8f9fa;
  color: #222222;
  padding: 2% 4%;
}

.insurancePlans h1 {
  color: #c61c1c;
  font-weight: bold;
}

.insurancePlans p,
.insurancePlans a {
  font-weight: bold;
  color: #222222;
  padding-top: 0;
}

.insurancePlans a:hover {
  color: #c61c1c;
  text-decoration: none;
}

.insuranceList {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 2% 0 1%;
}

.insuranceList li {
  width: 33%;
  list-style: disc;
  list-style-position: inside;
}

.insuranceContact {
  background-color: #c61c1c;
  display: flex;
  align-items: center;
  padding: 2% 4%;
}

.insuranceContactLeft {
  width: 70%;
  text-align: left;
}

.insuranceContact h2,
.insuranceContact p {
  color: white;
}

.insuranceContact p {
  margin-top: 2%;
}

.insuranceContactBtn {
  margin: 3% 0;
  width: 200px;
}

/* About Page */
.aboutHeader {
  background-image: url("./images/mark_family.jpg");
  background-repeat: no-repeat;
  background-position-y: 30%;
  background-size: cover;
  height: 60vh;
}

.aboutHeader h1 {
  color: #c61c1c;
  font-size: 3.6rem;
  font-weight: bold;
  padding-top: 1%;
}

.profileSection {
  background-color: #222222;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
}

.aboutShea,
  .aboutNat,
  .aboutJade,
  .aboutBritt {
  background-color: #313131;
}

.aboutPage h1 {
  color: #c61c1c;
}

.aboutPage h3 {
  color: #666666;
}

.aboutPage img {
  width: 20%;
  margin: 4%;
  border-radius: 0.25rem;
}

.profilesDiv p {
  color: white;
  margin: 2% 0;
}

.aboutText {
  text-align: left;
  margin: 4% 6% 4% 0%;
}

.aboutTextAlt {
  margin: 4% 0% 4% 4%;
}

.socialMediaLinks {
  margin-top: 4%;
}

.socialMediaLinks i {
  font-size: 1.8rem;
  color: #f8f9fa;
  padding-right: 3%;
}

.socialMediaLinks i:hover {
  color: #c61c1c;
}

.moreInfo {
  background-color: #f8f9fa;
  display: flex;
  flex-direction: column;
  height: 30vh;
  align-items: center;
  justify-content: center;
  padding-top: 2%;
}

.moreInfo h2 {
  font-size: 2.4rem;
  font-weight: bold;
}

.readyP {
  color: #222222;
}

.contactRiseBtn {
  width: 150px;
  background-color: #c61c1c;
  margin-top: 1%;
}

/* Media Queries */
@media (max-width: 1200px) {
  .scheduleBtn {
    margin-bottom: 10%;
  }
}

@media (max-width: 1024px) {
  .desktopNav,
  .navContact {
    display: none;
  }

  .riseNavLogo_a,
  .hamburgerMenu {
    display: block;
  }

  .riseNavLogo1 {
    width: 35%;
    padding: 1% 0;
  }

  a.nav-link,
  a.dropdownMenu.btn.btn-secondary,
  span.navbar-text {
    font-size: 1.4rem;
  }

  a.dropdownMenu.btn.btn.btn-secondary {
    margin-top: 7.5%;
  }

  .footerTop {
    margin: 0 2.5% 1.5%;
  }

  .footerLogo a,
  .footerLogo img {
    width: 90%;
  }

  .footerContact p,
  .footerTop a {
    font-size: 1.4rem;
  }

  .markEmail,
  .addressLink,
  .footerContact div {
    margin-left: -5px;
  }

  .footerTop i {
    font-size: 2.6rem;
  }

  .servicesDiv {
    height: 100%;
  }

  .dryNeedlingIntake img {
    width: 40%;
  }

  .whatToExpectText {
    padding: 0 2%;
  }

  .riseLobbyImg {
    margin-left: 0;
    width: 50%;
  }

  .whatYouNeed div {
    width: 30%;
  }

  .aboutPage img {
    width: 35%;
    padding: 3%;
  }

  .profilesDiv p {
    margin: 4% 0;
  }
}

@media (max-width: 900px) {
  footer h3 {
    font-size: 1.6rem;
  }

  .footerTop i {
    font-size: 2.2rem;
  }

  div.footerLogo,
  div.footerContact {
    width: 30%;
  }

  div.footerSocial {
    width: 35%;
  }

  p.copyright {
    font-size: 1.2rem;
  }

  .servicesDiv {
    flex-direction: column;
  }

  .patientImg {
    display: none;
  }

  .servicesList {
    width: 100%;
  }

  .sportsMedHeader p {
    width: 60%;
  }

  .servicesHeaderText,
  .ratesCard {
    width: 80%;
    line-height: 1.5;
  }

  .getStarted h1 {
    font-size: 2.8rem;
  }

  .planDiv div {
    width: 30%;
  }

  .insurancePlans {
    padding-left: 5%;
  }

  .insuranceContact {
    justify-content: space-between;
  }

  .insuranceContactLeft {
    width: 60%;
  }

  .insuranceContactRight {
    margin-right: 4%;
  }

  .aboutPage p {
    font-size: 1.4rem;
  }

  .aboutPage img {
    width: 40%;
  }

  .aboutText {
    margin-right: 4%;
  }

  .contactPage {
    flex-direction: column;
    align-items: center;
  }

  #firstName,
  #lastName,
  #email,
  #phone {
    width: 300px;
  }

  #description {
    width: 620px;
  }

  .businessInfo {
    width: 68%;
    margin: 5% 0 3%;
  }
}

@media (max-width: 768px) {
  .riseNavLogo_a {
    width: 33%;
  }

  .riseNavLogo1 {
    width: 60%;
  }

  p,
  li,
  button {
    font-size: 1.4rem;
  }

  button.requestApptBtn.scheduleBtnHome {
    width: 200px;
    height: 40px;
  }

  button.requestApptBtn.insuranceContactBtn {
    width: 175px;
  }

  button.learnBtn.btn.btn-secondary {
    width: 100px;
    height: 30px;
    font-size: 1.2rem;
  }

  .footerTop {
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-left: 6%;
  }

  .footerTop div {
    width: 50%;
  }

  .footerTop div.footerLogo {
    width: 100%;
  }

  .footerLogo img {
    width: 35%;
  }

  .footerContact div {
    width: 100%;
  }

  .servicesImgDiv h1 {
    font-size: 3rem;
  }

  .subheading {
    font-size: 1.6rem;
  }

  #cashRates img {
    width: 80%;
  }

  .PTEval h2,
  .followUp h2,
  .manualPT h2 {
    font-size: 1.8rem;
  }

  .PTEval h1,
  .followUp h1,
  .manualPT h1 {
    font-size: 2.8rem;
    padding-top: 5%;
  }

  .getStarted h1 {
    font-size: 2.6rem;
  }

  .planDiv div {
    width: 33%;
    padding: 2% 1%;
  }

  .planDiv div i {
    font-size: 2.4rem;
  }

  .requestApptBtn {
    width: 200px;
    height: 40px;
  }

  .whyVisit h2 {
    font-size: 1.8rem;
  }

  .sportsMedBtn {
    margin-left: 12%;
  }

  .approachText h1 {
    font-size: 2rem;
  }

  .approachText {
    flex-direction: column;
    align-items: center;
  }

  .approachText img {
    border-radius: 0.25rem;
    margin-top: 4%;
  }

  .approachText div {
    margin-top: 3%;
  }

  .sportsMedHeader h1 {
    font-size: 2.6rem;
  }

  .dryNeedlingPage h2 {
    font-size: 1.8rem;
  }

  .dryNeedlingHeader h1 {
    font-size: 2.2rem;
  }

  .howItHelps {
    padding: 3% 2%;
  }

  .dryNeedleBtn {
    margin-left: 10%;
  }

  .dryNeedlingIntake div {
    margin: 3% 5%;
  }

  .whatToExpect {
    flex-direction: column;
    align-items: center;
  }

  .whatToExpectText {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
  }

  .riseLobbyImg {
    display: none;
  }

  .uponArrival h1 {
    font-size: 2rem;
  }

  .whatYouNeed div {
    width: 33%;
  }

  .whatYouNeed h3 {
    font-size: 1.6rem;
  }

  .formHeader h1,
  .insuranceHeader h1 {
    font-size: 3rem;
  }

  .insuranceList li {
    width: 50%;
  }

  .formsDiv {
    flex-wrap: wrap;
  }

  .formsDiv a {
    width: 50%;
  }

  .formsDiv img {
    width: 60%;
    margin: 5% 0;
  }

  .aboutHeader {
    height: 50vh;
  }

  .aboutHeader h1 {
    font-size: 3.2rem;
    padding-top: 2%;
  }

  .aboutMark,
  .aboutColton,
  .aboutBrad,
  .aboutIngrid,
  .aboutKim {
    flex-direction: column;
    align-items: center;
  }

  .aboutText {
    padding: 0 5%;
  }

  .aboutShea,
  .aboutNat,
  .aboutJade,
  .aboutBritt {
    flex-direction: column-reverse;
    align-items: center;
  }

  .aboutPage img {
    width: 35%;
    margin: 5% 0 0;
    padding: 0;
  }

  .moreInfo h2 {
    font-size: 2rem;
  }

  .contactRiseBtn {
    width: 125px;
    height: 35px;
  }

  #firstName,
  #lastName,
  #email,
  #phone {
    width: 275px;
  }

  #description {
    width: 570px;
  }
}

@media (max-width: 600px) {
  p,
  li,
  button {
    font-size: 1.2rem;
  }

  h2 {
    font-size: 1.8rem;
  }

  .nav {
    padding-top: 2%;
  }

  .riseNavLogo1 {
    width: 90%;
  }

  .homeHero div img {
    width: 70%;
  }

  button.requestApptBtn.scheduleBtnHome {
    width: 170px;
    margin-bottom: 5%;
  }

  .introDiv h2,
  .servicesList h2 {
    font-size: 2rem;
  }

  .servicesList {
    padding: 4% 6% 2%;
  }

  .insuranceContactLeft {
    width: 55%;
  }

  button.requestApptBtn.insuranceContactBtn {
    width: 150px;
  }

  .footerTop {
    margin-left: 3%;
  }

  .footerTop p,
  .footerTop a {
    font-size: 1.2rem;
  }

  .footerTop i {
    font-size: 2rem;
  }

  .servicesImgDiv h1 {
    font-size: 2.6rem;
  }

  .PTEval h2,
  .followUp h2,
  .manualPT h2 {
    font-size: 1.6rem;
  }

  .PTEval h1,
  .followUp h1,
  .manualPT h1 {
    font-size: 2.4rem;
    padding-right: 7%;
  }

  .getStarted h1 {
    font-size: 2.2rem;
    padding-top: 4%;
  }

  .planDiv div {
    height: 175px;
  }

  .planDiv h3 {
    font-size: 1.4rem;
  }

  .requestApptBtn {
    width: 175px;
    margin-bottom: 5%;
  }

  .serviceHeaderImg {
    width: 25%;
  }

  .equipImg {
    width: 30%;
  }

  .headerImgDiv img {
    width: 25%;
  }

  .sportsMedHeader {
    padding: 5% 0;
  }

  .sportsMedHeader h1 {
    font-size: 2.4rem;
  }

  .shouldersHeader {
    height: 60vh;
  }

  .shouldersHeader h1 {
    font-size: 2.2rem;
    padding-top: 4%;
  }

  .shouldersHeader h2 {
    font-size: 1.6rem;
  }

  .approachText h1 {
    font-size: 1.8rem;
  }

  .dryNeedlingHeader {
    height: 40vh;
  }

  .dryNeedlingHeader img {
    width: 40%;
  }

  .dryNeedlingHeader h1 {
    font-size: 1.8rem;
  }

  .dryNeedlingHeader p {
    width: 80%;
  }

  .dryNeedlingIntake h2 {
    font-size: 1.6rem;
  }

  .patientsLogo {
    width: 50%;
  }

  .uponArrival h1 {
    font-size: 1.8rem;
  }

  .whatYouNeed {
    width: 100%;
    margin: 0;
  }

  .whatYouNeed div {
    padding: 0 3%;
  }

  .whatYouNeed p {
    margin-bottom: 15%;
  }

  .formHeader h1,
  .insuranceHeader h1 {
    font-size: 2.6rem;
  }

  .insurancePlans h1 {
    font-size: 2.2rem;
  }

  .patientForms h2 {
    font-size: 2.4rem;
  }

  .aboutHeader {
    height: 40vh;
  }

  .aboutHeader h1 {
    font-size: 2.8rem;
  }

  .aboutPage img {
    width: 30%;
  }

  .aboutPage h1 {
    font-size: 2rem;
  }

  .aboutPage h3 {
    font-size: 1.6rem;
  }

  .aboutPage p {
    font-size: 1.2rem;
  }

  .socialMediaLinks i {
    font-size: 1.6rem;
    padding-right: 5%;
  }

  .contactRiseBtn {
    width: 125px;
    height: 35px;
  }

  .contactPage h2 {
    font-size: 2.6rem;
    margin-top: 3%;
  }

  #firstName,
  #lastName,
  #email,
  #phone {
    width: 225px;
  }

  #description {
    width: 470px;
  }
}

@media (max-width: 480px) {
  .navBar {
    justify-content: space-evenly;
    padding: 3% 0;
  }

  .riseNavLogo_a {
    width: 50%;
  }

  .riseNavLogo1 {
    width: 80%;
  }

  .phxLogo,
  .navbar-brand {
    display: none;
  }

  .footerNav {
    justify-content: center;
  }

  .servicesImgDiv h1 {
    font-size: 2.2rem;
  }

  .subheading {
    font-size: 1.4rem;
  }

  .PTEval h1,
  .followUp h1,
  .manualPT h1 {
    font-size: 2rem;
    padding-top: 7%;
  }

  #cashRates {
    padding: 3% 0 5%;
  }

  .getStarted h1 {
    font-size: 2rem;
  }

  .planDiv div {
    padding-left: 3%;
    padding-right: 3%;
  }

  .planDiv div i {
    font-size: 2rem;
  }

  .planDiv div h3 {
    font-size: 1.4rem;
  }

  .sportsMedHeader h1 {
    font-size: 2rem;
  }

  .whyVisit h2 {
    margin-top: 4%;
  }

  .whyVisit div {
    flex-direction: column;
    width: 100%;
  }

  .whyVisit p {
    width: 100%;
    margin: 3% 0;
  }

  .sportsMedBtn {
    margin-left: 0;
    margin-right: 0;
  }

  .shouldersHeader {
    height: 65vh;
  }

  .shouldersHeader h1 {
    font-size: 1.8rem;
    font-weight: 500;
  }

  .shouldersHeader p {
    width: 60%;
  }

  .formHeader h1,
  .insuranceHeader h1 {
    font-size: 2.4rem;
  }

  .formHeader p {
    font-size: 1.6rem;
  }

  .insurancePlans {
    padding-right: 5%;
    justify-content: center;
  }

  .insuranceContact {
    flex-direction: column;
    padding-top: 4%;
  }

  .insuranceContactLeft {
    width: 100%;
  }

  button.requestApptBtn.insuranceContactBtn {
    width: 125px;
    margin-top: 15%;
    margin-bottom: 10%;
  }

  .formsDiv {
    flex-direction: column;
    align-items: center;
  }

  .formsDiv img {
    width: 70%;
  }

  .formsDiv a {
    width: 90%;
    border-bottom: 4px dotted #222222;
  }

  a.lastForm {
    border-bottom: none;
  }

  .aboutHeader h1 {
    padding-top: 5%;
  }

  .aboutPage img {
    width: 40%;
  }

  #firstName,
  #lastName,
  #email,
  #phone {
    width: 190px;
  }

  #description {
    width: 400px;
  }

  .submitBtn.btn.btn-secondary {
    height: 35px;
    font-size: 1.4rem;
  }
}

@media (max-width: 420px) {
  button.hamburgerNav.btn.btn-secondary {
    width: 50px;
    height: 35px;
  }

  .nav {
    margin-top: 0;
    padding-top: 0;
    padding-bottom: 1%;
  }

  .homeHero {
    flex-direction: column-reverse;
  }

  .homeHero div img {
    width: 50%;
    margin-top: 2%;
  }

  .riseDoorImg {
    width: 100%;
  }

  button.requestApptBtn.scheduleBtnHome {
    height: 35px;
  }

  .introDiv {
    padding: 3%;
  }

  .introDiv h2,
  .servicesList h2 {
    font-size: 1.8rem;
  }

  .introDiv p {
    padding: 1% 5%;
  }

  .videoDiv {
    width: 90%;
  }

  .footerTop {
    flex-direction: column;
    align-items: center;
  }

  .footerTop div {
    width: 100%;
    padding: 0 3%;
  }

  .footerContact div {
    padding: 0;
  }

  .PTEval,
  .followUp,
  .manualPT {
    align-items: center;
  }

  .PTEval h3,
  .followUp h3,
  .manualPT h3 {
    margin: 0;
    padding: 0;
  }

  button.requestApptBtn {
    height: 35px;
  }

  .sportsMedHeader p {
    width: 85%;
  }

  .whyVisit h2 {
    font-size: 1.6rem;
  }

  .shouldersHeader {
    height: 100%;
  }

  .howItHelpsText {
    flex-direction: column;
    margin: 0;
  }

  .howItHelps h2 {
    width: 100%;
    margin: 0;
    text-align: center;
  }

  .howItHelpsText p {
    width: 90%;
    margin: 4% 0;
  }

  .dryNeedlingIntake {
    flex-direction: column;
  }

  .dryNeedlingIntake div {
    width: 90%;
  }

  .dryNeedlingIntake img {
    margin: 0 4% 2%;
  }

  .whatToExpectText h2,
  .uponArrival h1 {
    font-size: 1.6rem;
  }

  .whatYouNeed h3 {
    font-size: 1.4rem;
  }

  .formHeader h1,
  .insuranceHeader h1 {
    font-size: 2.2rem;
  }

  .insurancePlans h1 {
    font-size: 2rem;
  }

  .aboutHeader {
    height: 35vh;
    background-position-y: 40%;
  }

  .aboutHeader h1 {
    padding-top: 3%;
  }

  .moreInfo h2 {
    font-size: 1.8rem;
  }

  #firstName,
  #lastName,
  #email,
  #phone {
    width: 165px;
  }

  #description {
    width: 350px;
  }

  .mapImg {
    width: 95%;
  }
}

@media (max-width: 360px) {
  .phxLogo {
    width: 50%;
  }

  a.nav-link,
  a.dropdownMenu.btn.btn-secondary {
    font-size: 1.2rem;
  }

  a.dropdownMenu.btn.btn-secondary {
    padding-top: 5%;
  }

  .introDiv h2,
  .servicesList h2 {
    font-size: 1.6rem;
  }

  .servicesImgDiv h1 {
    font-size: 2rem;
  }

  .ratesCard {
    width: 85%;
  }

  .sportsMedHeader {
    padding: 7% 0;
  }

  .sportsMedHeader h1 {
    font-size: 1.8rem;
  }

  .shouldersHeader p {
    width: 90%;
  }

  .formHeader h1,
  .insuranceHeader h1 {
    font-size: 2rem;
  }

  .insuranceList {
    height: 40vh;
    overflow: auto;
  }

  .insuranceList::-webkit-scrollbar {
    display: block;
    width: 10px;
  }

  .insuranceList::-webkit-scrollbar-track {
    background-color: #666666;
  }

  .insuranceList::-webkit-scrollbar-thumb {
    background-color: #313131;
  }

  .insuranceList li {
    width: 100%;
  }

  button.requestApptBtn.insuranceContactBtn {
    width: 100px;
  }

  .formsPage p {
    padding: 4% 0;
  }

  p.click {
    padding: 4% 4% 0;
  }

  #firstName,
  #lastName,
  #email,
  #phone {
    width: 140px;
  }

  #description {
    width: 300px;
  }
}
